import "components/Carousel/BusinessCampaignCarousel/BusinessCampaignCarousel.scss";
import Slider from "react-slick";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import {convertHtmlToReact} from "@hedgedoc/html-to-react/dist/convertHtmlToReact";

const Carousel = ({ items, primaryColour }) => {

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initalSlide: 0,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initalSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initalSlide: 0,
        },
      },
    ]
  };

  return (
    <div className="bblog-carousel-container">
      { items?.length > 0 && <Slider {...settings}>
        {
          items.map((item) => {
            return (
              <>
                <div className="blog-carousel-card" style={{backgroundColor: primaryColour}}>
                  {item?.image?.meta?.download_url ? (
                    <WagtailImage image={item?.image} alt={item.title} />
                  ) : (
                    <img src={item.image} />
                  )}
                  <div className="blog-carousel-content-container">
                    <h2>{convertHtmlToReact(item.description)}</h2>
                    <div className="bcarousel-btn">
                      {item.show_button?
                        <PageLink to={item.button_url} bold>
                          {item.button_text}
                        </PageLink>
                        : null}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </Slider>
      }
    </div>
  );
};

export default Carousel;
