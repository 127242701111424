import React, { useState, useRef, useEffect } from "react";
import {
  stepMinus,
  setEmailValidation,
  setUserInfo,
  setEmailStep,
} from "store/reducers/order/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/reusable/Button/Button";
import { NavLink, useLocation } from "react-router-dom";
import "components/OrderPage/StepThree/EmailVerification.scss";
import {
  useVerifyEmailMutation,
  useVerificationCodeMutation,
} from "api/beanfield/OrderPage";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { convertTag } from "utils/convertTag";
import { sendGoogleTagEvent } from "services/hooks/GoogleTags";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { internalUrlReaderWithLangReg } from "utils/wagtail";
import { getCookie } from "utils/Cookies";

const EmailVerification = ({ width }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [validationError, setValidationError] = useState(false);
  const [customerValidationError, setCustomerValidationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [noCodeError, setNoCodeError] = useState(false);
  const { email: userEmail, emailVerificationComplete } = useSelector(
    (state) => state.orderSlice.userInfo
  );
  const { emailStep: step } = useSelector((state) => state.orderSlice.userInfo);
  const [email, setEmail] = useState(userEmail || "");
  const [verificationCode, setVerificationCode] = useState("");
  const token = getCookie("token");

  //API CALL FOR EMAIL AND CODE VERIFICATION
  const [verifyEmail, { data: emailData, error: emailVerifyError }] =
    useVerifyEmailMutation();
  const [verifyCode, { data: codeData, error: codeError }] =
    useVerificationCodeMutation();
  //CMS DATA FETCH
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);

  //Focus on input when page loads
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
    if (emailVerificationComplete && userEmail) {
      dispatch(setEmailStep(3));
    }
    if (userEmail && !emailVerificationComplete) {
      dispatch(setEmailStep(2));
    }
  }, []);

  const handleInternalLinks = (link) => {
    let uri;

    if (link && link.includes("https://internallink.com")) {
      uri =
        "/" + internalUrlReaderWithLangReg(link, pathname);
    } else {
      uri = link;
    }
    return uri;
  };

  const transform = (node) => {
    if (node.type === "tag" && node.name === "a" && node.attribs.href) {
      node.attribs.href = handleInternalLinks(node.attribs.href);
    }
  };

  const resetErrors = () => {
    setNoCodeError(false);
    setValidationError(false);
    setCustomerValidationError(false);
  };

  //WHEN EMAIL IS SENT TO THE API, SET EMAIL VALIDATION, STORE EMAIL IN REDUX AND MOVE TO NEXT STEP (code verification)
  useEffect(() => {
    if (emailData?.success) {
      dispatch(setEmailValidation({ email }));
      dispatch(setEmailStep(2));
      if (token) {
        sendGoogleTagEvent({ eventName: "Lucky Checkout Step 6" });
      } else {
        sendGoogleTagEvent({ eventName: "Checkout Step 6" });
      }
    } else if (emailData?.error) {
      setCustomerValidationError(true);
      setErrorMessage(emailData.errors[0].email);
    }
  }, [emailData]);

  //IF CODE WAS VERIFIED - MOVE TO THE NEXT STEP AND SET EMAIL VALIDATION TO TRUE
  useEffect(() => {
    if (step === 2) {
      if (codeData?.verified && codeData?.success) {
        dispatch(setEmailValidation({ email }));
        dispatch(setEmailStep(3));
        if (token) {
          sendGoogleTagEvent({ eventName: "Lucky Checkout Step 7" });
        } else {
          sendGoogleTagEvent({ eventName: "Checkout Step 7" });
        }
      } else if (codeData && codeData?.error && verificationCode) {
        setValidationError(true);
      }
    }
  }, [codeData, step]);

  useEffect(() => {
    if ((emailVerifyError && step === 1) || (codeError && step === 2)) {
      setValidationError(true);
    }
  }, [emailVerifyError, codeError]);

  // CHECK EMAIL VALIDATION AND SEND VERIFICATION CODE
  const submitEmailForm = (e) => {
    e.preventDefault();
    if (email.includes("@") && email.includes(".")) {
      setValidationError(false);
      verifyEmail({ email: email ? email : userEmail });
      //try  dispatching the next step here
      if(emailData?.success) {
        dispatch(setUserInfo({ email }));
        setCustomerValidationError(false);
        dispatch(setEmailStep(2));
      }
    } else {
      setValidationError(true);
    }
  };

  //SEND VERIFICATION CODE FOR THE CONFIRMATION
  const submitVerificationForm = (e) => {
    e.preventDefault();
    setValidationError(false);
    verifyCode({ email, code: verificationCode });
    if (!e.target[0]?.value) {
      setNoCodeError(true);
    }
    else if (codeData?.verified && codeData?.success) {
      dispatch(setEmailStep(3));
    }
  };

  //TIMER FOR RETRY SENDING ANOTHER CODE
  const [seconds, setSeconds] = useState({
    time: 60,
    active: false,
  });

  useEffect(() => {
    if (seconds?.time > 0 && seconds.active) {
      setTimeout(
        () => setSeconds({ ...seconds, time: seconds?.time - 1 }),
        1000
      );
    }
    if (seconds?.time === 0) {
      setSeconds({ time: 60, active: false });
    }
  }, [seconds.active, seconds.time]);

  const timerForBtn = (data) => {
    return (
      <p
        style={{
          color: "gray",
          margin: 0,
          padding: 0,
          minWidth: "140px",
        }}
      >
        {data?.step_3_form_code_retry} {seconds?.time}
      </p>
    );
  };

  const resendBtns = (data) => {
    return (
      <div
        className={`stepThree_resend_container ${(!validationError|| !customerValidationError) ? "m2" : ""}`}
      >
        <NavLink
          onClick={() => {
            if (seconds?.active === false) {
              verifyEmail({ email });
              if (seconds?.time === 0 || seconds?.time === 60) {
                setSeconds({ ...seconds, active: true });
              }
            }
          }}
          className="stepThree_resend_links"
        >
          {seconds?.time === 0 || seconds?.time === 60
            ? data?.step_3_form_code_btn_resend
            : timerForBtn(data)}
        </NavLink>
        <NavLink
          onClick={() => {
            //TODO RESET TIMER
            dispatch(setEmailStep(1));
            resetErrors();
          }}
          className="stepThree_resend_links"
        >
          {data?.step_3_form_code_btn_edit}
        </NavLink>
      </div>
    );
  };

  //CONVERTING HTML TAGS TO REACT TO INJECT DATA INSTEAD OF CMS TAG

  return (
    <div className="stepThree_content_container">
      {step === 1 ? (
        <div className="stepThree_email_form">
          <h2 tabIndex="0">{data?.step_3_email_verification_header}</h2>
          {width < 768 ? <hr /> : null}
          {/* ENTER EMAIL AND SEND THE CODE */}
          <form onSubmit={submitEmailForm}>
            <p tabIndex="0">{data?.step_3_email_verification_body}</p>
            <div className="stepThree_email_wrapper">
              <div className="stepThree_email_input">
                <label htmlFor="email">{data?.step_3_form_email_title}</label>
                <input
                  placeholder={data?.step_3_form_email_placeholder || ""}
                  ref={inputRef}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onFocus={(e) => resetErrors(e)}
                  className={`stepThree_input ${
                    (validationError || customerValidationError) ? "error" : ""
                  }`}
                  type="text"
                  id="email"
                  aria-label={data?.step_3_form_email_title}
                  autoFocus={width >= 768}
                />
              </div>
              <Button
                style={{ height: "2.5rem" }}
                btnSytle={"beanfield-button Wbold"}
              >
                {data?.step_3_form_email_btn_submit}
              </Button>
            </div>
            {validationError ? (
              <span className="stepThree_error_text" tabIndex="0">
                {data?.step_3_email_error_text}
              </span>
            ) : null }
            {customerValidationError ? (
              <span className="stepThree_error_text" tabIndex="0">
                {convertHtmlToReact(errorMessage, {transform: transform})}
              </span>
            ) : null}
          </form>
        </div>
      ) : null}
      {step === 2 ? (
        <div className="stepThree_email_form">
          <h2 tabIndex="0">{data?.step_3_email_verification_header}</h2>
          {width < 768 ? <hr /> : null}
          {/* ENTER VERIFICATION CODE */}
          <form onSubmit={submitVerificationForm}>
            <p tabIndex="0">
              {data?.step_3_form_code_body_text?.includes("{email}")
                ? convertTag(data?.step_3_form_code_body_text, email, "{email}")
                : data?.step_3_form_code_body_text}
            </p>
            <div className="stepThree_email_wrapper">
              <div className="stepThree_email_input">
                <label htmlFor="verificationCode">{data?.step_3_form_code_title}</label>
                <input
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  onFocus={(e) => resetErrors(e)}
                  className={`stepThree_input ${
                    validationError || noCodeError ? "error" : ""
                  }`}
                  type="text"
                  id="verificationCode"
                  aria-label={data?.step_3_form_code_title}
                />
              </div>
              {width < 768 ? resendBtns(data) : null}
              <Button
                style={{ height: "2.5rem" }}
                btnSytle={"beanfield-button Wbold"}
                type="submit"

              >
                {data?.step_3_form_code_btn_submit}
              </Button>
            </div>
            {validationError && !noCodeError ? (
              <span className="stepThree_error_text">
                {data?.step_3_code_error_text}
              </span>
            ) : null}
            {(noCodeError) ? (
              <span className="stepThree_error_text">
                Please enter a value
              </span>
            ) : null}
            {width > 768 ? resendBtns(data) : null}
          </form>
        </div>
      ) : null}
      {step === 3 ? (
        <div className="stepThree_email_form MH">
          <h2 tabIndex="0">{data?.step_3_email_verification_header}</h2>
          {width < 768 ? <hr /> : null}
          <span>
            <p tabIndex="0">{data?.step_3_form_email_complete_body}</p>
          </span>
        </div>
      ) : null}
      <div className="stepThree_btn_container">
        {width > 768 ? (
          <Button
            btnSytle={"beanfield-button--hollow Wbold"}
            click={() => dispatch(stepMinus())}
            style={{ width: "10.9375rem" }}
          >
            {data?.step_3_back_btn_txt}
          </Button>
        ) : null}
        <Button
          disabled={step !== 3}
          btnSytle={"beanfield-button Wbold"}
          click={() => dispatch(setEmailValidation({ verification: true }))}
          style={{ width: width > 768 ? "10.9375rem" : "100%" }}
        >
          {data?.step_3_next_btn_txt}
        </Button>
      </div>
    </div>
  );
};

export default EmailVerification;
