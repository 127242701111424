import React from "react";
import "pages/Home/HomePage.scss";
import Carousel from "components/Carousel/HeaderCarousel/Carousel";
import TextMarquee from "components/Marquee/TextMarquee/TextMarquee";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import Badges from "components/Badges/BadgesContainer";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import { getQueries } from "api/wagtailAdapter";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import ImagePackage from "components/reusable/Package/ImagePackage";
import { useLocation } from "react-router";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import Head from "components/reusable/Head/Head";

const HomePage = () => {
  // If we're in the preview environment we need to get query
  //  parameters passed to the page and pass them back to the API.
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetHomePageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield | Services résidentiels" :
          "Beanfield | Residential Services"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield specializes in fibre Internet and TV services in Toronto. Unlimited usage. Fast speeds. No contracts or fine print." :
          "Spécialiste en service Internet et télévision par fibre optique à Toronto. Usage illimité. Vitesses rapides. Pas de contrat ni d'astérisque."
        }
        imageName={"HomePage"}
        locale={useLocation().pathname.split("/")[1]}
      />
      {navigator.userAgent !== 'ReactSnap' ? (
        <>
          <Carousel items={info?.main_carousel} showArrows />

          <h2 className="homepage-title" tabIndex="0">{info?.header}</h2>

          <div className="homepage-package-container">
            {info?.sections.map((item, i) => {
              return (
                <ImagePackage
                  item={{ ...item, id: i + 1 }}
                  center={i === 0 || info?.sections.length === 2}
                  index={i}
                  length={info?.sections.length}
                  key={i}
                />
              );
            })}
          </div>

          { info?.text_wheel_text_a || info?.text_wheel_text_b ? (
            <TextMarquee
              textOne={info?.text_wheel_text_a}
              textTwo={info?.text_wheel_text_b}
              colorPrimary={info?.text_wheel_colour_a}
              colorSecondary={info?.text_wheel_colour_b}
            />
          ) : null
          }

          <div className="homepage-pricing" tabIndex="0">
            <h2>{info?.why_choose_us_header}</h2>

            <p>{info?.why_choose_us_description}</p>

            <PageLink to={info?.why_choose_us_link_url} bold arrow={"true"}>
              {info?.why_choose_us_link_text}
            </PageLink>
          </div>

          <Badges badges={info?.badges} />
          { info?.video_url || info?.image ? (
            <div className="homepage-video-container">
              {info?.video_url === null ? (
                <WagtailImage image={info?.image} />
              ) : (
                <VideoPlayer
                  text={info?.video_text}
                  // Remember to sanitize this url
                  url={info?.video_url}
                />
              )}
            </div>
          ) : (
            <div/>
          )}
          {info?.contact_us_page?.badges?.length > 0 && (
            <ContactInfo
              title={info.contact_us_page.title}
              description={info.contact_us_page.description}
              badges={info.contact_us_page.badges}
            />
          )}
        </>
        ) : null}
    </div>
  );
};

export default HomePage;
