import React, { useCallback, useEffect, useState } from "react";
import "components/OrderPage/StepTwo/StepTwo.scss";
import YourAddress from "components/OrderPage/YourAddress/YourAddress";
import {
    stepPlus,
    stepMinus,
    setInstallationTime,
    setUnavailableDates,
    resetUnavailableDates,
    setInstallationTime2,
    setHasSelectedInstallationTime,
    setInstallationDate
} from "store/reducers/order/orderSlice";
import Button from "components/reusable/Button/Button";
import Calendar from "components/Calendar";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import MobileSteps from "components/OrderPage/MobileSteps/MobileSteps";
import useWindowDimensions from "utils/WindowWidth";
import {
  useLazyGetAvailableTimeSlotsQuery,
} from "api/beanfield/OrderPage";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import { useLocation } from "react-router-dom";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { sendGoogleTagEvent } from "services/hooks/GoogleTags";
import { getCookie } from "utils/Cookies";

const StepTwo = () => {
  const dispatch = useDispatch();
  const { order, userInfo } = useSelector((state) => state.orderSlice);
  const { width } = useWindowDimensions();
  const { localization } = useSelector((state) => state.localizationSlice);
  const hasSelectedInstallationTime = order?.hasSelectedInstallationTime;
  const token = getCookie("token");

  let isContinueDisabled;
  if (!!order?.installationDate && !!order.installationTime) {
    isContinueDisabled = false;
  }
  else {
    isContinueDisabled = true;
  }

  //CMS API CALL
  const { data: cmsData } = useGetOrderPageData(useLocation);

  // THIS API CALL IS TO GET THE SLOT TIMES FOR THE ENTIRE MONTH
  // TO BE ABLE TO GRAY OUT INACTIVE DAYS ON THE CALENDAR THAT ARE NOT AVAILABLE
  // "setMonthOfInstallation" is passed into <Calendar /> component below
  const [getAllTimeSlots, { data: allTimeSlots, isLoading }] =
    useLazyGetAvailableTimeSlotsQuery();

  const [monthOfInstallation, setMonthOfInstallation] = useState(
    dayjs(order?.installationDate).format("YYYY-MM-DD")
  );

  // track when all calendar data has been loaded
  const [calendarDataLoaded, setCalendarDataLoaded] = useState(false);

  const [specificData, setSpecificData] = useState([]);
  // filter available time slots for a given a given data
  const filterDate = ((date, data) => {
    if (data && date) {
      return data.filter(slot => {
            return slot.date === date;
          })
    }
  });

  useEffect(() => {
    // set the first available date
    if (allTimeSlots !== undefined && allTimeSlots.length > 0) {
      // format installation date set on the order object in the store
      const formattedDate = dayjs(order?.installationDate).format("YYYY-MM-DD");
      // check if the set installation date is the list of available installation dates
      const isWithinAvailaibleDates = allTimeSlots.find(el => el.date === formattedDate) || null;
      // if not set the installation date to be the first available date
      if (!isWithinAvailaibleDates) {
         dispatch(setInstallationDate(allTimeSlots[0].date));
      }
    }
  });

  useEffect(() => {
    if (monthOfInstallation && monthOfInstallation !== "Invalid Date") {
      const installationYear = dayjs(monthOfInstallation).format("YYYY");
      const installationMonth = dayjs(monthOfInstallation).format("MM");
      const startDate = dayjs(
        `${installationYear}-${installationMonth}-01`
      ).format("YYYY-MM-DD");
      const endDate = dayjs(`${installationYear}-${installationMonth}`)
        .endOf("month")
        .format("YYYY-MM-DD");
      setCalendarDataLoaded(false);
      getAllTimeSlots({
        address_id: userInfo?.address_id,
        startDate,
        endDate,
        unitNumber: userInfo?.unit,
        token: token,
      });
    }
  }, [monthOfInstallation]);

  useEffect(() => {
    if (order?.installationDate) {
      const date = dayjs(order.installationDate).format("YYYY-MM-DD");
        if(allTimeSlots){
          const filteredDates = filterDate(date, allTimeSlots);
         setSpecificData(filteredDates);
      }
    }
  }, [order?.installationDate]);

  useEffect(() => {
    if (order?.installationDate) {
      const date = dayjs(order.installationDate).format("YYYY-MM-DD");
        if(allTimeSlots){
          const filteredDates = filterDate(date, allTimeSlots);
         setSpecificData(filteredDates);
      }
    }
  }, [allTimeSlots]);

  // SET DATES THAT ARE NOT AVAILABLE
  useEffect(() => {
    missingDaysCallback();
    // all data computations are done. Calendar is done loading
    setCalendarDataLoaded(true);
    return () => {
      dispatch(resetUnavailableDates());
    };
  }, [allTimeSlots]);

  const missingDaysCallback = useCallback(() => {
    // This function loops through dates returned from the API and sets missing dates as unavailable
    const everyDayOfTheMonthFromApi = [];
    if (allTimeSlots) {
      allTimeSlots?.forEach((item) => {
        everyDayOfTheMonthFromApi.push(item?.date);
      });
      const daysInOrder = [...new Set(everyDayOfTheMonthFromApi)].sort();
      const today = dayjs();
      const installationYear = dayjs(monthOfInstallation).format("YYYY");
      const installationMonth = dayjs(monthOfInstallation).format("MM");
      const startDate = dayjs(
        `${installationYear}-${installationMonth}-01`
      ).format("YYYY-MM-DD");
      const endDate = dayjs(`${installationYear}-${installationMonth}`)
        .endOf("month")
        .format("YYYY-MM-DD");
      // If today is between start and end of month, use today. Otherwise, use first of month (startDate)
      let dayChecked = today > dayjs(startDate) && today < dayjs(endDate) ? today.format("YYYY-MM-DD") : startDate;
      // Set today as unavailable if no availability was returned from API for today
      if (dayChecked === today.format("YYYY-MM-DD") && daysInOrder.indexOf(today.format("YYYY-MM-DD")) < 0) {
        dispatch(setUnavailableDates(today.format("YYYY-MM-DD")));
      }
      for (let i = 0; i < daysInOrder.length; i++) {
        // If is the first iteration use 0 to prevent skipping 1st day
        let innerValue = (i === 0) ? i : 1;
        // Adds one day to day being checked until it matches next day with availability
        while (
          dayjs(dayChecked).add(innerValue, "day").format("YYYY-MM-DD") !==
          daysInOrder[i] &&
          dayjs(dayChecked).add(innerValue, "day").format("YYYY-MM-DD") < daysInOrder[i]
          ) {
          dispatch(
            setUnavailableDates(
              dayjs(dayChecked).add(innerValue, "day").format("YYYY-MM-DD")
            )
          );
          innerValue++;
        }
        dayChecked = daysInOrder[i];
      }
      // If last available date was not the end of the month, continue where left off
      if (dayjs(dayChecked).format("D") !== dayjs(endDate).format("D")) {
        for (let j = (allTimeSlots.length > 0) ? 1 : 0; j <= Number(dayjs(endDate).format("D")) - Number(dayjs(dayChecked).format("D")); j++) {
          dispatch(
            setUnavailableDates(
              dayjs(dayChecked).add(j, "day").format("YYYY-MM-DD")
            )
          );
        }
      }
    }
  }, [allTimeSlots]);

  const continueToVerification = () => {
    dispatch(stepPlus());
    if (token) {
      sendGoogleTagEvent({ eventName: "Lucky Set Up Account - Start"});
    } else {
      sendGoogleTagEvent({ eventName: "Set Up Account - Start"});
    }
  };

  // REFERENCE OBJECT FOR TIME SLOTS, THESE ARE RENDERED ON THE PAGE
  const times = {
    Morning: { en: "8:00 AM – 11:00 AM", fr: "8h00 – 11h00" },
    Afternoon: { en: "11:00 AM – 2:00 PM", fr: "11h00 – 14h00" },
    Evening: { en: "2:00 PM – 5:00 PM", fr: "14h00 – 17h00" },
    Night: { en: "5:00 PM – 8:00 PM", fr: "17h00 – 20h00" },
  };

  //THIS FUNCTION CHECKS WHICH TIME SLOTS ARE AVAILABLE FOR THE SPECIFIC DAY WHEN CLICKED
  const availabilityMatchingSelectedDate = specificData?.filter(
    (item) => item?.date === dayjs(order?.installationDate).format("YYYY-MM-DD")
  );
  return (
    <>
      <div className="stepTwo_main_container">
        {/* MOBILE COMPONENT */}
        {width < 768 ? (
          <div className="step_two_mobile_container">
            <div className="mobile_your_address_container">
              <YourAddress />
            </div>
            <MobileSteps activeStep={1}>
              {/* LEFT SIDE */}
              <div className="mobile_calendar_container">
                <Calendar setMonthOfInstallation={setMonthOfInstallation} cmsData={cmsData}  calendarMonthLoaded={calendarDataLoaded}/>
              </div>

              {/* RIGHT SIDE */}
              <div className="mobile_availability_container">
                <h2>
                  {cmsData?.step_2_availability_header}{" "}
                  {dayjs(order?.installationDate)
                    .locale(localization)
                    .format("dddd, MMMM DD")}
                </h2>
                <span>
                  {convertHtmlToReact(cmsData?.step_2_availability_body)}
                </span>
                <div className="mobile_availability_buttons_container">
                  {Object.entries(times).map((slot, key) => {
                    const isAvailable = availabilityMatchingSelectedDate?.find(
                      (item) => item?.period === slot[0]
                    );
                    return (
                      <Button
                        style={{ fontFamily: "WalsheimRegular" }}
                        disabled={!isAvailable}
                        btnSytle={"beanfield-button--hollow"}
                        click={() => {
                          dispatch(setInstallationTime(slot[1][localization]));
                          dispatch(setInstallationTime2(slot[0]));
                        }}
                        key={key}
                      >
                        {slot[1][localization]}
                      </Button>
                    );
                  })}
                </div>
              </div>

              <div className="mobile_button_container">
                <Button
                  style={{ width: "100%" }}
                  disabled={isContinueDisabled}
                  btnSytle={"beanfield-button Wbold"}
                  click={ continueToVerification }
                >
                  {cmsData?.step_2_next_btn_txt}
                </Button>
              </div>
            </MobileSteps>
          </div>
        ) : (
          <div className="stepTwo_webView_container">
            {/* WEB COMPONENT */}
            <div className="step_two_webView_yourAddress_container">
              <div className="step_two_webView_yourAddress_left">
                <YourAddress />
              </div>
              <div className="step_two_webView_yourAddress_right" />
            </div>

            <div className="step_two_webView_calendar_container">
              {/* LEFT SIDE */}
              <div className="step_two_webView_calendar_left">
                <Calendar setMonthOfInstallation={setMonthOfInstallation} cmsData={cmsData} calendarMonthLoaded={calendarDataLoaded}/>
              </div>
              {/* RIGHT SIDE */}
              <div className="step_two_webView_calendar_right">
                <h2 tabIndex="0">
                  {cmsData?.step_2_availability_header}{" "}
                  {dayjs(order?.installationDate)
                    .locale(localization)
                    .format("dddd, MMMM DD")}
                </h2>
                <span tabIndex="0">
                  {convertHtmlToReact(cmsData?.step_2_availability_body)}
                </span>
                <div className="step_two_webView_calendar_slot_buttons">
                  {Object.entries(times).map((slot) => {
                    const isAvailable = availabilityMatchingSelectedDate?.find(
                      (item) => item?.period === slot[0]
                    );
                    return (
                      <Button
                        disabled={!isAvailable}
                        style={{
                          width: "100%",
                          flexWrap: "nowrap",
                          minWidth: "14.375rem",
                          border: "1px solid #4B4A4A",
                          fontFamily: "WalsheimRegular",
                        }}
                        btnSytle={"beanfield-button--hollow"}
                        click={() => {
                          dispatch(setInstallationTime(slot[1][localization]));
                          dispatch(setInstallationTime2(slot[0]));
                          if(!hasSelectedInstallationTime) {
                              sendGoogleTagEvent({ eventName: "Checkout Step 5" });
                          }
                          else{
                            sendGoogleTagEvent({ eventName: "Checkout Step 5b" });
                          }
                          dispatch(setHasSelectedInstallationTime());
                        }}
                      >
                        {slot[1][localization]}
                      </Button>
                    );
                  })}
                </div>
              </div>
              {/* BUTTONS */}
            </div>
            <div className="calendar-buttons-container">
              <div className="step_two_webView_calendar_buttons">
                <Button
                  btnSytle={"beanfield-button--hollow Wbold"}
                  click={() => dispatch(stepMinus())}
                  style={{ width: "10.9375rem" }}
                >
                  {cmsData?.step_2_back_btn_txt || "Back"}
                </Button>
                <Button
                  disabled={isContinueDisabled}
                  btnSytle={"beanfield-button Wbold"}
                  click={ continueToVerification }
                  style={{ width: "10.9375rem" }}
                >
                  {cmsData?.step_2_next_btn_txt || "Continue"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StepTwo;
