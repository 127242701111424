let  internetPackageIds = [2040, 2083, 2084];
let googleEventMap = {
    2040: '2gbps Availability Check',
    2083: '4gbps Availability Check',
    2084: '8gbps Availability Check'
};

if (process.env.REACT_APP_NODE_ENV === 'production' ) {
    internetPackageIds = [2040, 2052, 2064];
    googleEventMap = {
        2040: '2gbps Availability Check',
        2052: '4gbps Availability Check',
        2064: '8gbps Availability Check'
    };
}

export {
    internetPackageIds,
    googleEventMap,
}