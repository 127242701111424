import { createApi } from "@reduxjs/toolkit/query/react";
import { baseDraftQuery } from "api/config";
import { localeDict, regionDict } from "../util/dicts";

const processRegion = (region) => {
  if (region in regionDict) {
    return regionDict[region];
  }
  return "ca";
};

const generateDraftQuery = ({ queryParams, localePath, region, tag }) => {
  const { content_type, page_id, token } = Object.fromEntries(
    new URLSearchParams(queryParams)
  );
  const locale = localeDict[localePath];
  const _region = processRegion(region);
  return {
    url: `/${page_id}/${queryParams}&locale=${locale}&REGION=${_region}`,
    providesTags: [tag],
  };
};

export const Page = createApi({
  reducerPath: "Page",
  baseQuery: baseDraftQuery,
  tagTypes: [
    "Home",
    "Start",
    "ServiceInternet",
    "ServicePhone",
    "ServiceTelevision",
    "ServiceOTG",
    "WhyUs",
    "Accessibilty",
    "Legal",
    "TermsOfService",
    "AcceptableUse",
    "MDU",
    "PublicWifi",
    "Technical",
    "SupportLanding",
    "SupportFAQ",
    "AboutUs",
    "Contact",
    "ChannelLineup",
    "HowWeHire",
    "LifeAtBeanfield",
    "RealityOfSpeed",
    "InternetSpeeds101",
    "TestContactSection",
    "Survey",
    "BusinessCampaign"
  ],
  endpoints: (builder) => ({
    getHomePage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "Home",
        }),
    }),
    getStartPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "Start",
        }),
    }),
    getServiceInternetPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "ServiceInternet",
        }),
    }),
    getServicePhonePage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "ServicePhone",
        }),
    }),
    getServiceTelevisionPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "ServiceTelevision",
        }),
    }),
    getServiceOTGPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "ServiceOTG",
        }),
    }),
    getWhyUsPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "WhyUs",
        }),
    }),
    getAccessibilityPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "Accessibility",
        }),
    }),
    getLegalPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "Legal",
        }),
    }),
    getTermsOfServicePage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "TermsOfService",
        }),
    }),
    getAcceptableUsePolicyPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "AcceptableUse",
        }),
    }),
    getMDUAccessAgreementsPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "MDU",
        }),
    }),
    getPublicWifiPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "PublicWifi",
        }),
    }),
    getTechnicalPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "Technical",
        }),
    }),
    getSupportLandingPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "SupportLanding",
        }),
    }),
    getSupportFAQPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "SupportFAQ",
        }),
    }),
    getAboutUsPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "AboutUs",
        }),
    }),
    getContactPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "Contact",
        }),
    }),
    getChannelLineupPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "ChannelLineup",
        }),
    }),
    getHowWeHirePage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "HowWeHire",
        }),
    }),
    getLifeAtBeanfieldPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "LifeAtBeanfield",
        }),
    }),
    getInternetSpeeds101Page: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "InternetSpeed101",
        }),
    }),
    getRealityOfSpeedPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "RealityOfSpeed",
        }),
    }),
    getCareersPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "TestContactSection",
        }),
    }),
    getTestContactPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "TestContactSection",
        }),
    }),
    getMainHeader: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "MainHeader",
        }),
    }),
    getCareersHeader: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "CareersHeader",
        }),
    }),
    getMainFooter: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "MainFooter",
        }),
    }),
    getOrderPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const { content_type, page_id, token } = Object.fromEntries(
          new URLSearchParams(queryParams)
        );
        const locale = localeDict[localePath];
        return {
          url: `/${page_id}/${queryParams}&locale=${locale}&REGION=${region}`,
        };
      },
    }),
    getAvailabilityMap: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "AvailabilityMapPage",
        }),
    }),
    getSurveyPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "Survey",
        }),
    }),
    getBusinessCampaignPage: builder.query({
      query: ({ queryParams, localePath, region }) =>
        generateDraftQuery({
          queryParams,
          localePath,
          region,
          tag: "BusinessCampaign",
        }),
    }),
  }),
});

export const {
  useGetMainHeaderQuery,
  useLazyGetMainHeaderQuery,
  useGetCareersHeaderQuery,
  useLazyGetCareersHeaderQuery,
  useGetMainFooterQuery,
  useLazyGetMainFooterQuery,
  useGetHomePageQuery,
  useLazyGetHomePageQuery,
  useGetStartPageQuery,
  useLazyGetStartPageQuery,
  useGetServiceInternetPageQuery,
  useLazyGetServiceInternetPageQuery,
  useGetServicePhonePageQuery,
  useLazyGetServicePhonePageQuery,
  useGetServiceTelevisionPageQuery,
  useLazyGetServiceTelevisionPageQuery,
  useGetServiceOTGPageQuery,
  useLazyGetServiceOTGPageQuery,
  useGetWhyUsPageQuery,
  useLazyGetWhyUsPageQuery,
  useGetAccessibilityPageQuery,
  useLazyGetAccessibilityPageQuery,
  useGetLegalPageQuery,
  useLazyGetLegalPageQuery,
  useGetTermsOfServicePageQuery,
  useLazyGetTermsOfServicePageQuery,
  useGetAcceptableUsePolicyPageQuery,
  useLazyGetAcceptableUsePolicyPageQuery,
  useGetMDUAccessAgreementsPageQuery,
  useLazyGetMDUAccessAgreementsPageQuery,
  useGetPublicWifiPageQuery,
  useLazyGetPublicWifiPageQuery,
  useGetTechnicalPageQuery,
  useLazyGetTechnicalPageQuery,
  useGetSupportLandingPageQuery,
  useLazyGetSupportLandingPageQuery,
  useGetSupportFAQPageQuery,
  useLazyGetSupportFAQPageQuery,
  useGetAboutUsPageQuery,
  useLazyGetAboutUsPageQuery,
  useGetContactPageQuery,
  useLazyGetContactPageQuery,
  useGetOrderPageQuery,
  useLazyGetOrderPageQuery,
  useGetChannelLineupPageQuery,
  useLazyGetChannelLineupPageQuery,
  useGetHowWeHirePageQuery,
  useLazyGetHowWeHirePageQuery,
  useGetLifeAtBeanfieldPageQuery,
  useLazyGetLifeAtBeanfieldPageQuery,
  useGetInternetSpeeds101PageQuery,
  useLazyGetInternetSpeeds101PageQuery,
  useGetRealityOfSpeedPageQuery,
  useLazyGetRealityOfSpeedPageQuery,
  useGetTestContactPageQuery,
  useLazyGetTestContactPageQuery,
  useGetCareersPageQuery,
  useLazyGetCareersPageQuery,
  useGetAvailabilityMapQuery,
  useGetSurveyPageQuery,
  useLazyGetSurveyPageQuery,
  useGetBusinessCampaignPageQuery,
  useLazyGetBusinessCampaignPageQuery,
} = Page;
