import React, { useState } from "react";
import "components/BusinessCampaign/LogoComponent.scss";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";

const LogoComponent = ({
    title_text,
    logo,
 }) => {
    return (
      <>
        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="logo-component-container">
              <div className="title-text" tabIndex="0">
                {convertHtmlToReact(title_text)}
              </div>
              <div className="logos-container">
                {logo.map((logo, index) => {
                  return (
                    <div key={index}>
                      <WagtailImage image={logo}/>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default LogoComponent;