import React from "react";
import "components/BusinessCampaign/HighlightComponent.scss";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import Button from "components/reusable/Button/Button";

const HighlightComponent = ({
    main_text,
    submit_text,
    use_column_layout,
    primary_colour,
}) => {

    const goToForm = () => {
        const element = document.getElementById("campaign-form");
        if (element) {
            const yOffset = -80;
            const yTarget =
              element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: yTarget, behavior: "smooth" });
        }
    };

    return (
      <>
          <div className="resi-container">
              <div className="resi-margin-container">
                  <div
                    className={use_column_layout ? "use-column-layout highlight-component-container" : "highlight-component-container"}
                    style={{backgroundColor: primary_colour}}

                  >
                      <div className="content" tabIndex="0">
                          {convertHtmlToReact(main_text)}
                      </div>
                      <Button btnStyle="campaign-button" click={goToForm}>{submit_text}</Button>
                  </div>
              </div>
          </div>
      </>
    );
};

export default HighlightComponent;