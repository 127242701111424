import React, { useEffect, useState } from "react";
import "components/ChannelPackages/ChannelPackages.scss";
import Star from "assets/Icons/Star";
import Caret from "assets/Icons/Caret";
import ThemeCard from "components/ChannelPackages/ThemeCard/ThemeCard";
import ChannelCard from "components/ChannelPackages/ChannelCard/ChannelCard";
import {
  useLazyGetPremiumPackageQuery,
  useLazyGetBasicPackageQuery,
} from "api/beanfield/ChannelList";

const ChannelPackages = ({ info }) => {
  const [theme, setTheme] = useState(true);
  const [themeData, setThemeData] = useState(null);
  const [alaCarte, setALaCarte] = useState(false);
  const [alaCarteData, setALaCarteData] = useState(null);
  const [expand, setExpand] = useState(false);
  const [PremiumPackage] = useLazyGetPremiumPackageQuery();
  const [BasicPackage] = useLazyGetBasicPackageQuery();

  const handleSwitch = () => {
    setTheme(!theme);
    setALaCarte(!alaCarte);
  };

  useEffect(() => {
    PremiumPackage().then((premRes) => {
      BasicPackage().then((basRes) => {
        getPremiumTheme(premRes, basRes);
        getPremiumCarte(premRes, basRes);
      });
    });
  }, []);

  const getPremiumCarte = (premium, basic) => {
    if (navigator.userAgent !== 'ReactSnap') {
      let premiumCarte = JSON.parse(
        JSON.stringify(premium.data?.tvPackage.alaCarteGroups)
      );
      let basicCarte = basic.data?.tvPackage.alaCarteGroups;
      const premiumCarteDict = {};
      premiumCarte.forEach((pack) => {
        premiumCarteDict[pack.price] = pack;
      });

      const basicCarteDict = {};
      basicCarte.forEach((pack) => {
        basicCarteDict[pack.price] = pack;
      });

      for (const [price, pack] of Object.entries(premiumCarteDict)) {
        if (!Object.keys(basicCarteDict).includes(price)) {
          pack.premium = true;
        } else {
          pack.premium = false;
        }
      }

      premiumCarte = [];

      for (const pack of Object.values(premiumCarteDict)) {
        premiumCarte.push(pack);
      }
      setALaCarteData(premiumCarte);
    } else {
      setALaCarteData([]);
    }
  };

  const getPremiumTheme = (premium, basic) => {
    if (navigator.userAgent !== 'ReactSnap') {
      let premiumTheme = JSON.parse(
        JSON.stringify(premium.data?.tvPackage.themePacks)
      );
      let basicTheme = basic.data?.tvPackage.themePacks;

      const basicThemeDict = {};
      basicTheme.forEach((pack) => {
        basicThemeDict[pack.name] = pack;
      });

      const premiumThemeDict = {};
      premiumTheme.forEach((pack) => {
        premiumThemeDict[pack.name] = pack;
      });

      for (const [name, theme] of Object.entries(premiumThemeDict)) {
        if (!Object.keys(basicThemeDict).includes(name)) {
          theme.premium = true;
        } else {
          theme.premium = false;
        }
      }

      premiumTheme = [];
      for (const theme of Object.values(premiumThemeDict)) {
        premiumTheme.push(theme);
      }

      setThemeData(premiumTheme);
    } else {
      setThemeData([]);
    }
  };

  return (
    <div className="channel-package-container">
      <h2>{info?.channel_package_title}</h2>

      <div className="channel-package-select">
        <p
          className={`channel-select-text ${theme ? "Wbold" : null}`}
          aria-selected={theme}
          onClick={() => handleSwitch()}
        >
          {info?.channel_package_theme}
        </p>
        <p
          className={`channel-select-text ${alaCarte ? "Wbold" : null}`}
          aria-selected={alaCarte}
          onClick={() => handleSwitch()}
        >
          {info?.channel_package_carte}
        </p>
      </div>

      <div className="channel-select">
        <h3>{info?.channel_package_subtitle}</h3>
        <div className="channel-text-container">
          <Star />
          <p>{info?.channel_package_star}</p>
        </div>

        <div className="channel-card-container" aria-expanded={expand}>
          {theme
            ? themeData?.map((item, index) => {
              return <ThemeCard item={item} key={index} />;
            })
            : alaCarte
              ? alaCarteData?.map((item, index) => {
                return <ChannelCard item={item} seeAllChannelsString={info?.channel_package_see_all_channels} key={index}/>;
              })
              : null}

          {theme ? (
            themeData?.length <= 4 ? null : (
              <div
                onClick={() => setExpand(!expand)}
                className="channel-gradient"
                aria-expanded={expand}
              >
                <p className="Wbold">{expand ? info?.channel_package_collapse : (info?.channel_package_view_all + ` (${themeData?.length})`)}</p>
                <Caret color={"#325a32"} turn={expand ? "up" : "down"} />
              </div>
            )
          ) : alaCarte ? (
            alaCarteData?.length <= 4 ? null : (
              <div
                onClick={() => setExpand(!expand)}
                className="channel-gradient"
                aria-expanded={expand}
              >
                <p className="Wbold">{expand ? info?.channel_package_collapse : info?.channel_package_view_all}</p>
                <Caret color={"black"} turn={expand ? "up" : "down"} />
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ChannelPackages;
