import React, { useState } from "react";
import "components/ChannelPackages/ChannelCard/ChannelCard.scss";
import Star from "assets/Icons/Star";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { modalContents } from "components/OrderPage/Accordions/ModalContents";
import { useDispatch } from "react-redux";

const ChannelCard = ({ item, seeAllChannelsString }) => {
  const dispatch = useDispatch();
  const [seeMore, setSeemore] = useState(
    item.channels.length > 6 ? true : false
  );

  return (
    <div className="channel-card">
      <div className="channel-card-text">
        <p className="Wbold">
          <span className="Wbold">$</span>
          {item.price}
          <span>/mo.</span>
          {!item.premium ? null : (
            <div className="channel-card-star">
              <Star />
            </div>
          )}
        </p>
      </div>
      <div className="channel-card-image-container">
        {item.channels.map((channel, index) => {
          if (index < 6) {
            return (
              <div className="image-logo">
                <img
                  alt={channel.name}
                  src={channel.logoUrl}
                  title={channel.name}
                />
              </div>
            );
          }
        })}
      </div>
      {!seeMore ? null : (
        <p
          onClick={() =>
            dispatch(
              toggleModal({
                children: modalContents.alaCarte(item.channels),
              })
            )
          }
          className="Wbold channel-card-all-channels-text"
        >
          {seeAllChannelsString}
        </p>
      )}
    </div>
  );
};

export default ChannelCard;
