import React, { useEffect } from "react";
import "components/OrderPage/StepOne/StepOne.scss";
import TotalOrder from "components/OrderPage/TotalOrder/TotalOrder";
import Accordion from "components/reusable/Accordion/Accordion";
import { Accordions } from "components/OrderPage/Accordions/Accordions";
import Button from "components/reusable/Button/Button";
import YourAddress from "components/OrderPage/YourAddress/YourAddress";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setInternet, stepPlus } from "store/reducers/order/orderSlice";
import useWindowDimensions from "utils/WindowWidth";
import MobileSteps from "components/OrderPage/MobileSteps/MobileSteps";
import { useGetOrderPageData } from "services/api/useGetOrderPageData";
import {
  useGetInternetPackagesQuery,
  useGetTvPackagesQuery,
  useGetLongDistanceRatesQuery,
  useGetHomePhonePackagesQuery,
} from "api/beanfield/OrderPage";
import { sendGoogleTagEvent, sendSelectedInternetPackageEvent } from "services/hooks/GoogleTags";
import Info from "assets/svg/Info";
import { internalUrlReaderWithLangReg } from "utils/wagtail";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { getCookie } from "utils/Cookies";

const StepOne = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { order, userInfo } = useSelector((state) => state.orderSlice);
  const location = useSelector((state) =>
    state.orderSlice.userInfo.province.toLowerCase()
  );
  const { width } = useWindowDimensions();
  const { localization } = useSelector((state) => state.localizationSlice);
  const radioBtn = true;
  let { data, isLoading, isError, error } = useGetOrderPageData(useLocation);
  const token = getCookie("token");

  //INTERNET PACKAGES
  const { data: internetPackages } = useGetInternetPackagesQuery({
    address_id: userInfo?.address_id,
  });

  //TV PACKAGES
  const { data: tvPackages } = useGetTvPackagesQuery({
    address_id: userInfo?.address_id,
  });

  //HOME PHONE PACKAGES
  const { data: homePhonePackages } = useGetHomePhonePackagesQuery({
    address_id: userInfo?.address_id,
  });

  //LONG DISTANCE RATES
  const { data: longDistanceRates } = useGetLongDistanceRatesQuery();

  const continueOrder = () => {
    dispatch(stepPlus());
    if (token) {
      sendGoogleTagEvent({ eventName: "Lucky Order Services Selected" });
    } else {
      sendGoogleTagEvent({ eventName: "Order Services Selected" });
      sendSelectedInternetPackageEvent( order.internet.title );
    }
  };

  const handleInternalLinks = (link) => {
    let uri;

    if (link && link.includes("https://internallink.com")) {
      uri =
        "/" + internalUrlReaderWithLangReg(link, pathname);
    } else {
      uri = link;
    }
    return uri;
  };

  const transform = (node) => {
    if (node.type === "tag" && node.name === "a" && node.attribs.href) {
      node.attribs.href = handleInternalLinks(node.attribs.href);
    }
  };

  //MISCELANEOUS info for internet packages (needed for order submit at the end)
  useEffect(() => {
    if (internetPackages) {
      dispatch(
        setInternet({
          selected: order.internet.selected === "" ? internetPackages?.packages[0]?.id : order.internet.selected,
          cpe_router: {
            id: internetPackages?.packages[0]?.includedServices?.cpe_router?.id,
          },
          wifi_ap: {
            id: internetPackages?.packages[0]?.includedServices?.wifi_ap?.id,
          },
        })
      );
      if (token) {
        sendGoogleTagEvent({ eventName: "Lucky Checkout Step 1" });
      } else {
        sendGoogleTagEvent({ eventName: "Checkout Step 1" });
      }
    }
  }, [internetPackages]);

  if (!internetPackages || !tvPackages) return <div>Loading...</div>;

  return (
    <>
      {/* LEFT SIDE */}
      <div className="orderPage_left_block_container">
        <YourAddress />
        {userInfo?.unitHasService ? (
          <div className="unit-has-service-container" >
            <div className="left-content" tabIndex="0">
              <Info />
            </div>
            <div className="right-content" tabIndex="0">
              {convertHtmlToReact(data?.step_1_active_service_warning, {transform: transform})}
            </div>
          </div>
        ) : null}
        {/* MOBILE COMPONENT */}
        {width < 768 ? (
          <MobileSteps activeStep={0}>
            <div style={{ padding: "2rem 0rem 2rem 1rem" }}>
              <div style={{ marginTop: "1rem" }}>
                {/* INTERNET PACKAGES */}
                {data && internetPackages?.packages ? (
                  <Accordion
                    type="internet"
                    info_icon
                    checkbox
                    title={data?.step_1_internet_header}
                  >
                    {internetPackages?.packages.length > 1
                      ? Accordions.accordion_multiple_internet(
                          dispatch,
                          order,
                          radioBtn,
                          internetPackages?.packages,
                          data
                        )
                      : Accordions.accordion_single_internet(
                          data,
                          internetPackages?.packages,
                          pathname
                        )}
                  </Accordion>
                ) : null}
              </div>
              {/* TV PACKAGES */}
              {location !== "bc" ? (
                <>
                  {tvPackages?.packages?.length > 0 && (
                    <>
                      <div style={{ marginTop: "1rem" }} />
                      <Accordion
                        type="tv"
                        checkbox
                        title={data?.step_1_tv_header}
                      >
                        {Accordions.accordion_on_tv(
                          dispatch,
                          order,
                          radioBtn,
                          tvPackages?.packages,
                          data,
                          localization
                        )}
                      </Accordion>{" "}
                    </>
                  )}
                </>
              ) : null}
              {/* PHONE PACKAGES */}
              {homePhonePackages?.packages?.length > 0 && (
                <>
                  <div style={{ marginTop: "1rem" }} />
                  <Accordion
                    type="phone"
                    checkbox
                    title={data?.step_1_phone_header}
                  >
                    {Accordions.accordion_on_home_phone(
                      dispatch,
                      order,
                      longDistanceRates?.rateGroups,
                      homePhonePackages?.packages,
                      data
                    )}
                  </Accordion>{" "}
                </>
              )}
              <div className="orderPage_continue_btn_container">
                <Button
                  btnSytle={"beanfield-button Wbold"}
                  click={ continueOrder }
                >
                  {data?.step_1_next_btn_txt || "Continue"}
                </Button>
              </div>
            </div>
          </MobileSteps>
        ) : (
          // REGULAR VIEW COMPONENT (desktop)
          <>
            {/* INTERNET PACKAGES */}
            {data && internetPackages?.packages ? (
              <Accordion
                type="internet"
                info_icon
                checkbox
                title={data?.step_1_internet_header}
              >
                {internetPackages?.packages.length > 1
                  ? Accordions.accordion_multiple_internet(
                      dispatch,
                      order,
                      radioBtn,
                      internetPackages?.packages,
                      data
                    )
                  : Accordions.accordion_single_internet(
                      data,
                      internetPackages?.packages,
                    )}
              </Accordion>
            ) : null}
            {/* TV PACKAGES */}
            {location !== "bc" ? (
              <>
                {tvPackages?.packages?.length > 0 && (
                  <>
                    {" "}
                    <span style={{ marginTop: "1rem" }} />
                    <Accordion
                      type="tv"
                      checkbox
                      title={data?.step_1_tv_header}
                    >
                      {Accordions.accordion_on_tv(
                        dispatch,
                        order,
                        radioBtn,
                        tvPackages?.packages,
                        data,
                        localization
                      )}
                    </Accordion>{" "}
                  </>
                )}
              </>
            ) : null}
            {/* PHONE PACKAGES */}
            {homePhonePackages?.packages?.length > 0 && (
              <>
                <span style={{ marginTop: "1rem" }} />
                <Accordion
                  type="phone"
                  checkbox
                  title={data?.step_1_phone_header}
                >
                  {Accordions.accordion_on_home_phone(
                    dispatch,
                    order,
                    longDistanceRates?.rateGroups,
                    homePhonePackages?.packages,
                    data
                  )}
                </Accordion>
              </>
            )}
            <div className="orderPage_continue_btn_container">
              <Button
                btnSytle={"beanfield-button Wbold"}
                click={ continueOrder }
                style={{ width: "10.9375rem" }}
              >
                {data?.step_1_next_btn_txt || "Continue"}
              </Button>
            </div>
          </>
        )}
      </div>
      {/* RIGHT SIDE */}
      {width > 768 ? (
        <div className="orderPage_right_block_container">
          <TotalOrder />
        </div>
      ) : null}
    </>
  );
};

export default StepOne;
