import CheckMarkOrder from "assets/Icons/CheckMarkOrder";
import RadioBtn from "components/reusable/Radio/RadioBtn";
import {
  setInternet,
  setTv,
  setTvChannels,
  setTopBoxes,
  setThemePacks,
  reset,
} from "store/reducers/order/orderSlice";
import Minus from "assets/Icons/Minus";
import Plus from "assets/Icons/Plus";
import Accordion from "components/reusable/Accordion/Accordion";
import Checkbox from "components/reusable/Checkbox/Checkbox";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { modalContents } from "components/OrderPage/Accordions/ModalContents";
import ToolTip from "components/reusable/Tooltip/Tooltip";
import ToolTipMobile from "components/reusable/Tooltip/TooltipMobile";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { sendGoogleTagEvent } from "services/hooks/GoogleTags";
import { internalUrlReaderWithLangReg } from "utils/wagtail";

const handleInternalLinks = (link) => {
  let uri;
  const language = localStorage.getItem("language");
  const region = localStorage.getItem("region");
  if (link.includes("https://internallink.com")) {
    uri =
      "/" + internalUrlReaderWithLangReg(link, `/${language}/${region}/`);
  } else {
    uri = link;
  }
  return uri;
};

const handleViewLongDistanceRates = (event, cmsData, rates, dispatch) => {
  if (event.key === "Enter" || event.key === " ") {
    event.preventDefault();
    dispatch(
      toggleModal({
        children: modalContents.longDistancePackage([
          {
            country: cmsData.modal_international_long_distance_country_txt,
            landline: cmsData.modal_international_long_distance_call_to_landlines_txt,
            mobile: cmsData.modal_international_long_distance_call_to_mobiles_txt,
          },
          ...rates,
        ]),
      })
    );
  }
};

const handleViewChannels = (event, element, dispatch) => {
  if (event.key === "Enter" || event.key === " ") {
    event.preventDefault();
    dispatch(
      toggleModal({
        children: modalContents.starterPackage(
          element?.channels
        ),
      })
    );
  }
};

const transform = (node) => {
  if (node.type === "tag" && node.name === "a") {
    node.attribs.href = handleInternalLinks(node.attribs.href);
  }
};

const packages = (
  order,
  dispatch,
  themePacks,
  alacarte,
  topBoxes,
  cmsData,
  localization
) => {
  return (
    <>
      {/* SET-TOP BOXES */}
      <div className="orderPage_left_inner_misc_container" tabIndex="0">
        <div className="orderPage_left_inner_misc_additional_boxes_container">
          {convertHtmlToReact(cmsData?.step_1_set_top_box_header)}
          <div className="additional_boxes_btn_container">
            <div
              onClick={() => {
                if (order?.tv?.topBoxes?.selected > 0) {
                  dispatch(
                    setTopBoxes({
                      action: "del",
                      price: 5,
                    })
                  );
                }
              }}
              style={{
                background:
                  order?.tv?.topBoxes?.selected == 0 ? "#C7C7C7" : "#325a32",
              }}
              className="additional_boxes_btn"
            >
              <Minus
                fill={order?.tv?.topBoxes?.selected == 0 ? "#ACACAC" : "#fff"}
              />
            </div>
            <div>
              <p style={{ margin: "0 10px" }}>
                {order?.tv?.topBoxes?.selected}
              </p>
            </div>
            <div
              onClick={() =>
                dispatch(
                  setTopBoxes({
                    id: topBoxes?.choices.filter((item) => item?.price === 5)[0]
                      ?.id,
                    action: "add",
                    price: 5,
                  })
                )
              }
              className="additional_boxes_btn"
            >
              <Plus />
            </div>
          </div>
        </div>
        <p style={{ fontSize: "1.25rem" }}>$5{cmsData?.total_sfx}</p>
        <p style={{ margin: 0 }}>{cmsData?.step_1_set_top_box_body}</p>
      </div>

      {/* THEME PACKS */}
      <div className="orderPage_left_inner_misc_theme_pack_container">
        <Accordion
          inner
          title={cmsData?.step_1_theme_packs}
          subTitle={`(${order?.tv?.themePacks?.selected?.length} ${
            localization === "en" ? "of" : "de"
          } ${themePacks?.length} ${
            localization === "en" ? "selected" : "sélectionnés"
          })`}
        >
          {" "}
          <div className="orderPage_left_inner_misc_alacarte_container"  tabIndex="0">
            {themePacks?.map((individualPackage, index) => {
              const isItemSelected = order?.tv?.themePacks?.selected?.find(
                (item) => item.title === individualPackage?.title
              );
              return (
                <div key={index} className="channel_container">
                  <div className="channel_checkbox_container">
                    <Checkbox
                      order={order}
                      theme={individualPackage?.title}
                      onClick={(result) => {
                        dispatch(
                          setThemePacks({
                            action: !!result ? "add" : "del",
                            value: individualPackage,
                          })
                        );
                      }}
                      ariaLabel={individualPackage?.title}
                    />
                  </div>
                  <div className="description_container">
                    <p className={`${isItemSelected ? "Wbold" : ""} `}>
                      {individualPackage?.title}
                    </p>
                    <a
                      onClick={() => {
                        dispatch(
                          toggleModal({
                            children: modalContents.themePacks(
                              individualPackage?.channels,
                              individualPackage?.title
                            ),
                          })
                        );
                      }}
                    >
                      {cmsData?.step_1_all_channels}
                    </a>
                  </div>
                  <div className="price_container">
                    <p>{`$${individualPackage?.price}${cmsData?.total_sfx}`}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Accordion>
      </div>

      {/* A LA CARTE CHANNELS */}
      <div className="orderPage_left_inner_misc_theme_pack_container">
        <Accordion
          inner
          title={cmsData?.step_1_a_la_carte}
          subTitle={`(${order?.tv?.channels?.selected?.length} of ${alacarte?.length} selected)`}
        >
          <div className="orderPage_left_inner_misc_alacarte_container">
            {alacarte?.map((channel, index) => {
              const isItemSelected = order?.tv?.channels?.selected?.find(
                (item) => item.title === channel?.title
              );
              return (
                <div key={channel?.channelNumber} className="channel_container">
                  <div className="alacarte_list_container" tabIndex="0">
                    <div style={{ marginTop: "0.6rem" }}>
                      <Checkbox
                        order={order}
                        channel={channel?.title}
                        onClick={(result) => {
                          dispatch(
                            setTvChannels({
                              action: !!result ? "add" : "del",
                              value: channel,
                              price: channel?.price,
                            })
                          );
                        }}
                      />
                    </div>
                    <img
                      style={{
                        maxHeight: "20px",
                        marginTop: "0.6rem",
                        marginLeft: "15px",
                      }}
                      src={channel?.logoUrl}
                      alt={channel?.title}
                    ></img>
                    <p className={`${isItemSelected ? "Wbold" : ""} `}>
                      {channel?.title}
                    </p>
                  </div>
                  <div>
                    <p>{`$${channel?.price}${cmsData?.total_sfx}`}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Accordion>
      </div>
    </>
  );
};

export const Accordions = {
  accordion_single_internet: (data, internetPackages) => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    return (
      <div className="orderPage_left_fibre_internet_on_container">
        <div className="orderPage_left_fibre_internet_promo" tabIndex="0">
          {convertHtmlToReact(data?.step_1_internet_promo)}
        </div>
        <div className="orderPage_left_inner_container" tabIndex="0">
          <div className="orderPage_left_inner_wrapper">
            <div className="orderPage_left_inner_title">
              <h3>{internetPackages[0]?.title}</h3>
              <h3>
                ${internetPackages[0]?.price}{data?.total_sfx}.
              </h3>
            </div>
            <p>{internetPackages[0]?.description}</p>

            <ul>
              {internetPackages[0]?.packageFeatures?.map((feature, index) => {
                return (
                  <li key={index} className="orderPage_left_inner_feature">
                    <CheckMarkOrder
                      style={{ marginTop: width < 768 ? "5px" : "-5px" }}
                    />
                    {convertHtmlToReact(feature?.text)}
                    {feature?.tooltip.length > 0 ? (
                      width > 768 ? (
                        <ToolTip
                          containerStyles={{ display: "inline-block"}}
                          contentStyles={{ top: -5 }}
                          helperStyles={{ top: 0, left: 15 }}
                          iconStyles={{ marginBottom: 0, marginTop: 0, verticalAlign: "middle" }}
                        >
                          {convertHtmlToReact(feature?.tooltip, {transform: transform})}
                        </ToolTip>
                      ) : (
                        <ToolTipMobile
                          containerStyles={{ display: "inline-block"}}
                          iconStyles={{ marginBottom: 0, marginTop: 0, verticalAlign: "middle" }}
                        >
                          {convertHtmlToReact(feature?.tooltip, {transform: transform})}
                        </ToolTipMobile>
                      )
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  },

  accordion_multiple_internet: (
    dispatch,
    order,
    radioBtn = false,
    internetPackages,
    cmsData
  ) => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    return (
      <>
        <div className="orderPage_left_fibre_internet_promo" tabIndex="0">
          {convertHtmlToReact(cmsData?.step_1_internet_promo)}
        </div>
        {internetPackages?.map((element, index) => {
          const elementTitle = element?.title?.split("-")[0] || element?.title;
          return (
            <div className="orderPage_left_fibre_internet_on_container" key={index}>
              <div className="orderPage_left_inner_container">
                {/* RADIO BTN */}
                {radioBtn ? (
                  <RadioBtn
                    onClick={() => {
                      dispatch(
                        setInternet({
                          selected: element?.id,
                          title: elementTitle,
                          price: element?.price,
                          internetPackageSelected: element?.id
                        })
                      )
                    }}
                    checked={order.internet.selected === element?.id}
                    value={order.internet.selected}
                    name="internet_package"
                    style={{ marginLeft: "0.75rem" }}
                  />
                ) : null}
                <div className="orderPage_left_inner_wrapper" tabIndex="0">
                  <div className="orderPage_left_inner_title">
                    <h3>{elementTitle}</h3>
                    <h3>
                      ${element?.price}{cmsData?.total_sfx}.
                    </h3>
                  </div>
                  <p>{element?.description}</p>
                  {/* FEATURES */}
                  <ul>
                    {element?.packageFeatures?.map((feature, index) => {
                      return (
                        <li
                          key={index}
                          className="orderPage_left_inner_feature"
                        >
                          <CheckMarkOrder style={{ marginBottom: "0.25rem" }} />
                          <p className="feature_wrapper">
                            {feature?.text}
                            {feature?.tooltip.length > 0 ? (
                              width > 768 ? (
                                <ToolTip
                                  containerStyles={{ display: "inline-block"}}
                                  contentStyles={{ top: -5 }}
                                  helperStyles={{ top: 0, left: 15 }}
                                  iconStyles={{ marginBottom: 0, marginTop: 0, verticalAlign: "middle" }}
                                >
                                  {convertHtmlToReact(feature?.tooltip, {transform: transform})}
                                </ToolTip>
                              ) : (
                                <ToolTipMobile
                                  containerStyles={{ display: "inline-block"}}
                                  iconStyles={{ marginBottom: 0, marginTop: 0, verticalAlign: "middle" }}
                                >
                                  {convertHtmlToReact(feature?.tooltip, {transform: transform})}
                                </ToolTipMobile>
                              )
                            ) : null}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <hr className="internet-package-separator"/>
            </div>
          );
        })}
      </>
    );
  },
  accordion_on_tv: (
    dispatch,
    order,
    radioBtn = false,
    tvPackages,
    cmsData,
    localization
  ) => {
    return (
      <div className="orderPage_left_fibre_internet_on_container">
        {tvPackages?.map((element, index) => {
          // if (index === 0) return null;
          const elementTitle = element?.title?.split("-")[1] || element?.title;

          return (
            <>
              <div className="orderPage_left_inner_container extra_padding_radioBtn">
                <div className="orderPage_left_inner_wrapper">
                  <div className="inner_wrapper_mobile">
                    {radioBtn ? (
                      <RadioBtn
                        onClick={() => {
                          // RESETTING TV OPTIONS TO REMOVE ADDONS
                          dispatch(reset());
                          dispatch(
                            setTv({
                              price: element?.price,
                              id: element?.id,
                              title: elementTitle,
                              storage: {
                                id:
                                  element?.storage?.choices?.filter(
                                    (item) => item?.hours === 25
                                  )[0]?.id || null,
                              },
                            })
                          );
                          sendGoogleTagEvent({ eventName: "Checkout Step 2"})
                        }}
                        checked={order.tv.id === element?.id}
                        name={order.tv.title}
                        style={{ alignSelf: "flex-start" }}
                      />
                    ) : null}
                    <div className="mobile_starter_package">
                      <div className="orderPage_left_inner_title" tabIndex="0">
                        <h3>{elementTitle}</h3>
                        <h3>{`$${element?.price}${cmsData?.total_sfx}.`}</h3>
                      </div>
                      <p tabIndex="0">
                        {element?.description || "No description available"}
                      </p>
                      <a
                        onClick={() =>
                          dispatch(
                            toggleModal({
                              children: modalContents.starterPackage(
                                element?.channels
                              ),
                            })
                          )
                        }
                        onKeyDown={(e) => handleViewChannels(e, element, dispatch)}
                        tabIndex="0"
                      >
                        {cmsData?.step_1_view_all_package_channels_1}{" "}
                        {element?.channels?.length}{" "}
                        {cmsData?.step_1_view_all_package_channels_2}
                      </a>
                    </div>
                  </div>
                  {/* INNER TV OPTIONS AND ACCORDIONS */}
                  {order?.tv.id === element?.id
                    ? packages(
                        order,
                        dispatch,
                        element.channelPacks,
                        element.alacarteChannels,
                        element.mediaCentre,
                        cmsData,
                        localization
                      )
                    : null}
                </div>
              </div>
              {tvPackages?.length > 1 && index !== tvPackages?.length - 1 ? (
                <hr className="horizontal_line" />
              ) : null}
            </>
          );
        })}
      </div>
    );
  },
  accordion_on_home_phone: (
    dispatch,
    order,
    longDistanceRates,
    homePhonePackages,
    cmsData,
    radioBtn = false
  ) => {
    return (
      <div className="orderPage_left_fibre_internet_on_container">
        <div className="orderPage_left_inner_container">
          {radioBtn ? <RadioBtn name="tv_package" /> : null}{" "}
          {/*if radio btn will be needed here later */}
          <div className="orderPage_left_inner_wrapper">
            <div
              style={{
                justifyContent: "space-between",
              }}
              className="orderPage_left_inner_title"
            >
              <div tabIndex="0">
                {homePhonePackages?.length > 0 ? (
                  <p>
                    {homePhonePackages[0]?.description ||
                      "No description available"}
                  </p>
                ) : null}
                <a
                  onClick={() =>
                    dispatch(
                      toggleModal({
                        children: modalContents.longDistancePackage([
                          {
                            country: cmsData.modal_international_long_distance_country_txt,
                            landline: cmsData.modal_international_long_distance_call_to_landlines_txt,
                            mobile: cmsData.modal_international_long_distance_call_to_mobiles_txt,
                          },
                          ...longDistanceRates,
                        ], cmsData),
                      })
                    )
                  }
                  onKeyDown={(e) => handleViewLongDistanceRates(e, longDistanceRates, dispatch)}
                  tabIndex="0"
                >
                  {cmsData?.step_1_view_distance_rates}
                </a>
              </div>
              <div>
                {homePhonePackages?.length > 0 ? (
                  <h3>{`$${homePhonePackages[0]?.price}${cmsData?.total_sfx}.`}</h3>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
