import React, {useEffect, useState} from "react";
import Hamburger from "hamburger-react";
import "components/BusinessCampaign/HamburgerMenu.scss";
import Caret from "assets/Icons/Caret";

import { useLocation, useNavigate} from "react-router-dom";
import { internalUrlReaderWithLangReg } from "utils/wagtail";

const HamburgerMenu = ({ links, langOptions, urlPosition}) => {

  const getInitialSelection = (urlPosition, langOptions, pathname) => {
    return langOptions?.find(lang => lang.value === pathname.split("/")[urlPosition]);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [languageIsOpen, setLanguageIsOpen] = useState(false);
  const [selection, setSelection] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const lang = getInitialSelection(urlPosition, langOptions, pathname);
    setSelection(lang);
  }, []);


  const handleChange = (lang) => {
    localStorage.setItem("language", lang.value);
    if (urlPosition === 1) {
      document.cookie = `_LOCALE_=${lang.value}; path=/`;
      navigate(
        `/${lang.value}/${pathname.split("/")[2]}/${pathname
          .split("/")
          .slice(3)
          .join("/")}`
      );
    }

    if (urlPosition === 2) {
      localStorage.setItem("region", lang.value);
      navigate(
        `/${pathname.split("/")[1]}/${lang.value}/${pathname
          .split("/")
          .slice(3)
          .join("/")}`
      );
    }
    setIsOpen(!isOpen);
    setSelection(lang);
  };

  return (
    <div className="hamburger-container">
      <div className="hamburger-title">
        <Hamburger
          rounded
          toggled={isOpen}
          toggle={setIsOpen}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      <div className="hamburger-menu" aria-expanded={!isOpen}>

       {links.map((link, index) => {
                if(link.is_external_link) {
                  return <div className="hamburger-item" key={index}>
                    <a href={link.link_url} className="nav-link">{link.link_text}</a>
                  </div>
                }
                else{
                    return <div className="hamburger-item" key={index}>
                      <a href={"/" + internalUrlReaderWithLangReg(link.link_url, pathname)}>
                        {link.link_text}
                    </a>
                    </div>
                }
            })}

        <div onClick={() => setLanguageIsOpen(!languageIsOpen)}>
          <div className="hamburger-language-item">
            <p>{selection?.label}</p>
            <Caret
              className="hamburger-carrot"
              aria-expanded={!languageIsOpen}
              color={"black"}
            />
          </div>

          <div className="hamburger-language" aria-expanded={!languageIsOpen}>
            {langOptions.map((lang, index) => {
              return (
                  <p  key={index} onClick={() => handleChange(lang)}>{lang.label}</p>
              );
            })}
          </div>
        </div>

      </div>
    </div>
  );
};

export default HamburgerMenu;
