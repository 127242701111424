import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "api/config";

const localeDict = {
  en: "en-CA",
  fr: "fr-CA",
};

export const BusinessCampaign = createApi({
  reducerPath: "BusinessCampaign",
  baseQuery,
  endpoints: (builder) => ({
    getBusinessCampaignPage: builder.query({
      query: ({ queryParams, localePath, region, slug }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=businessCampaign.BusinessCampaignPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}&slug=${slug}`,
          providesTags: ["BusinessCampaign"],
        };
      },
    }),
  }),
});

export const Page = createApi({
  reducerPath: "Page",
  baseQuery,
  tagTypes: [
    "Home",
    "Start",
    "InternetService",
    "PhoneService",
    "TelevisionService",
    "WhyUs",
    "Accessibility",
    "Legal",
    "TermsofService",
    "AcceptableUse",
    "MDU",
    "PublicWifi",
    "SupportLanding",
    "HowWeHire",
    "InternetSpeeds101",
    "Survey"
  ],
  endpoints: (builder) => ({
    getHomePage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=home.HomePage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["Home"],
        };
      },
    }),
    getStartPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=start.StartPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["Start"],
        };
      },
    }),
    getServiceInternetPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=serviceInternet.ServiceInternetPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["InternetService"],
        };
      },
    }),
    getServicePhonePage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=servicePhone.ServicePhonePage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["PhoneService"],
        };
      },
    }),
    getServiceTelevisionPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=serviceTelevision.ServiceTelevisionPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["TelevisionService"],
        };
      },
    }),
    getWhyUsPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=whyChooseUs.WhyChooseUsPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["WhyUs"],
        };
      },
    }),
    getAccessibilityPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=accessibility.AccessibilityPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["Accessibility"],
        };
      },
    }),
    getLegalPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=legal.LegalPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["Legal"],
        };
      },
    }),
    getTermsOfServicePage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=termsofservice.TermsOfServicePage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["TermsofService"],
        };
      },
    }),
    getAcceptableUsePolicyPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=acceptableuse.AcceptableusePage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["AcceptableUse"],
        };
      },
    }),
    getMDUAccessAgreementsPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=mdu.MduPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["MDU"],
        };
      },
    }),
    getPublicWifiPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=publicWifi.PublicWifiPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["PublicWifi"],
        };
      },
    }),
    getTechnicalPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=technical.TechnicalPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["Technical"],
        };
      },
    }),
    getSupportLandingPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=support.SupportHomePage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["SupportLanding"],
        };
      },
    }),
    getSupportFAQPage: builder.query({
      query: ({ queryParams, localePath, region, slug }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=support.SupportPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}&slug=${slug}`,
        };
      },
    }),
    getSearch: builder.query({
      query: ({ queryParams, localePath, region, searchQuery }) => {
        const locale = localeDict[localePath];
        return {
          url: `?search=${searchQuery.replace(
            " ",
            "+"
          )}&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getOrderPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=order_process.OrderProcessPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getHowWeHirePage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=howWeHire.HowWeHirePage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getLifeAtBeanfieldPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=lifeAtBeanfield.LifeAtBeanfield&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getInternetSpeeds101Page: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=internetSpeeds101.InternetSpeeds101Page&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["InternetSpeeds101"],
        };
      },
    }),
    getContactPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=contact.contactpage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getServiceOTGPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=serviceOTG.ServiceOTGPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getChannelLineupPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=channelLineup.channelLineupPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getAboutUsPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=aboutUs.AboutUsPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getCareersPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=careers.CareersPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getRealityOfSpeedPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=realityOfSpeed.RealityOfSpeedPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getMainHeader: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=Marginals.Mainheader&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getCareersHeader: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=Marginals.CareersHeader&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getMainFooter: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=Marginals.MainFooter&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getAvailabilityMap: builder.query({
      query: ({ queryParams, localePath, region, slug }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=availabilityMap.AvailabilityMapPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
        };
      },
    }),
    getSurveyPage: builder.query({
      query: ({ queryParams, localePath, region }) => {
        const locale = localeDict[localePath];
        return {
          url: `?type=survey.SurveyPage&fields=*&locale=${locale}&REGION=ca&REGION=${region}`,
          providesTags: ["Survey"],
        };
      },
    }),
  }),
});

export const {
  useGetMainHeaderQuery,
  useLazyGetMainHeaderQuery,
  useGetCareersHeaderQuery,
  useLazyGetCareersHeaderQuery,
  useGetMainFooterQuery,
  useLazyGetMainFooterQuery,
  useGetHomePageQuery,
  useLazyGetHomePageQuery,
  useGetStartPageQuery,
  useLazyGetStartPageQuery,
  useGetServiceInternetPageQuery,
  useLazyGetServiceInternetPageQuery,
  useGetServicePhonePageQuery,
  useLazyGetServicePhonePageQuery,
  useGetServiceTelevisionPageQuery,
  useLazyGetServiceTelevisionPageQuery,
  useGetWhyUsPageQuery,
  useLazyGetWhyUsPageQuery,
  useGetAccessibilityPageQuery,
  useLazyGetAccessibilityPageQuery,
  useGetLegalPageQuery,
  useLazyGetLegalPageQuery,
  useGetTermsOfServicePageQuery,
  useLazyGetTermsOfServicePageQuery,
  useGetAcceptableUsePolicyPageQuery,
  useLazyGetAcceptableUsePolicyPageQuery,
  useGetMDUAccessAgreementsPageQuery,
  useLazyGetMDUAccessAgreementsPageQuery,
  useGetPublicWifiPageQuery,
  useLazyGetPublicWifiPageQuery,
  useGetTechnicalPageQuery,
  useLazyGetTechnicalPageQuery,
  useGetSupportLandingPageQuery,
  useLazyGetSupportLandingPageQuery,
  useGetSupportFAQPageQuery,
  useLazyGetSupportFAQPageQuery,
  useGetSearchQuery,
  useLazyGetSearchQuery,
  useGetOrderPageQuery,
  useLazyGetOrderPageQuery,
  useGetHowWeHirePageQuery,
  useLazyGetHowWeHirePageQuery,
  useGetLifeAtBeanfieldPageQuery,
  useLazyGetLifeAtBeanfieldPageQuery,
  useGetInternetSpeeds101PageQuery,
  useLazyGetInternetSpeeds101PageQuery,
  useGetContactPageQuery,
  useLazyGetContactPageQuery,
  useGetServiceOTGPageQuery,
  useLazyGetServiceOTGPageQuery,
  useGetChannelLineupPageQuery,
  useLazyGetChannelLineupPageQuery,
  useGetAboutUsPageQuery,
  useLazyGetAboutUsPageQuery,
  useGetCareersPageQuery,
  useLazyGetCareersPageQuery,
  useGetRealityOfSpeedPageQuery,
  useLazyGetRealityOfSpeedPageQuery,
  useGetAvailabilityMapQuery,
  useGetSurveyPageQuery,
  useLazyGetSurveyPageQuery,
} = Page;

export const {
  useGetBusinessCampaignPageQuery,
  useLazyGetBusinessCampaignPageQuery,
} = BusinessCampaign;
